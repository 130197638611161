import mixpanel from "mixpanel-browser"
import { AnalyticsEvents } from "./events.js"
import {Utils} from "@/utils";

// Near entry of your product, init Mixpanel
mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
  debug: true,
  track_pageview: false,
  persistence: "localStorage"
})

class AnalyticsServiceInitializer {
  _promiseResolve = () => {}
  whenStarted = new Promise((resolve) => {
    this._promiseResolve = resolve
  })
  start() {
    this._promiseResolve()
  }
}

export const injectionKey = "$analytics"

export class AnalyticsService {
  requiresIdentity = true
  pageTracking = new AnalyticsServiceInitializer()
  globalProperties = {
    env: process.env.VUE_APP_MODE, // dev or prod
    appContext: "", // drawer | popup | auth-microservice
    appVersion: "", // as seen in packageJson
    hasExtensionInstalled: undefined,
  }

  reset() {
    mixpanel.reset()
    this.requiresIdentity = true
  }

  getGlobalProperties() {
    return Utils.removeEmptyProperties(this.globalProperties)
  }

  identifyUser(user = undefined) {
    const props = {
      $name: user.name,
      $email: user.email,
      credits: user.credits,
      isPremium: user.isPremium,
      referrerCode: user.referrerCode,
      userProfileUrl: user.userProfileUrl
    }

    if (this.requiresIdentity) {
      this.requiresIdentity = false
      mixpanel.identify(btoa(user.email))
      mixpanel.people.set(props)
    } else {
      mixpanel.people.set_once(props)
    }
  }

  setGlobalProperties(properties = {}) {
    this.globalProperties = { ...this.globalProperties, ...properties }
  }

  trackEvent(event, properties = {}) {
    mixpanel.track(event, { ...this.getGlobalProperties(), ...properties })
  }

  trackButtonClickEvent(properties = {}) {
    this.trackEvent(AnalyticsEvents.BUTTON_CLICK, properties)
  }

  trackPageView(properties = {}) {
    mixpanel.track_pageview({
      page: properties.fullPath,
      route: properties.routeName,
      ...this.getGlobalProperties()
    })
  }
}
